.static-page {
	padding: 80px 0 30px;

	.inner-banner {
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: 480px;

		background-position: center;

		@media (max-width:992px) {
			height: 180px;
		}

		&.bg-service {
			background-image: url(RESOURCE/img/bg-banner-service_01.jpg);
		}

		&.bg-leaflet {
			background-image: url(RESOURCE/img/bg-banner-leaflet.jpg);
		}

		&.bg-how-get-there {
			background-image: url(RESOURCE/img/bg-banner-how-get.jpg);
		}

		&.bg-travel {
			background-image: url(RESOURCE/img/bg-banner-travel.jpg);
		}

		&.bg-event {
			background-image: url(RESOURCE/img/bg-banner-service_02.jpg);
		}

		.contact-map {
			height: 320px;
		}
	}

	.icon-label {
		display: flex;
		align-items: center;
		padding-top: 15px;

		img {
			margin-right: 10px;
		}
	}

	.evnt-date {
		color: var(--secondarycolor);
	}
}

.margin-left_12 {
	margin-left: 12px;
}

.hintergund_grau_top {
	height: 60px;
	background-color: #f9f9f9;
	text-align: center;
	margin-right: 12px;
	margin-left: 12px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	clear: both;
}

.text_bold {
	font-family: 'fewo_bold';
	font-size: 32px;
	font-weight: 700;
}

.inhalt {

	width: 100%;
	height: auto;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.inhalt_mitte {
	max-width: 768px;
	background-color: #ffffff;
}

.kasten_01 {
	float: left;
	width: 356px;
	margin-right: 12px;
	margin-left: 12px;
	margin-bottom: 24px;
	background-color: #ffffff;
	border: 1px solid #58a0d1;
	border-radius: 4px;
}

.kasten_02 {
	width: 336px;
	height: 96px;
	margin-right: 12px;
	margin-left: 12px;
	margin-top: 12px;
	background-color: #ffffff;
}

.kasten_03 {
	width: 336px;
	margin-bottom: 12px;
	margin-right: 12px;
	margin-left: 12px;
	margin-top: 12px;
	background-color: #ffffff;
}

.kasten_leer {
	float: left;
	width: 360px;
	margin-right: 12px;
	margin-left: 12px;
	margin-bottom: 12px;
	background-color: #ffffff;
}

.text_option_01 {
	clear: both;
	max-width: 768px;
	margin-right: 12px;
	margin-left: 12px;
	margin-bottom: 12px;
}

.text_option_02 {}


.menu_option_01 {
	float: left;
	width: 168px;
	height: 36px;
	margin-right: 12px;
	margin-left: 12px;
	margin-bottom: 12px;
	background-color: #f6ac15;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menu_option_02_aussen {
	position: fixed;
	bottom: 0;
	left: 50%;
	margin-left: -84px;
}

.menu_option_02 {
	width: 168px;
	height: 36px;
	background-color: #f6ac15;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menu_option_01:hover {
	background-color: #c58a11;
	transition: .4s;
}

.menu_option_02:hover {
	background-color: #c58a11;
	transition: .4s;
}

.menu_text {
	color: #fff;
	text-decoration: none;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.text_body {
	text-align: center;
}

.text_head {
	font-variant: bold;
}

.contact-banner {
	margin-top: 105px;
	height: auto !important;

	.mapnote {
		padding: 15px;
	}

	.mapconsent {
		height: 490px;

		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: rgba(26, 121, 187, 0.15);
		flex-direction: column;
		padding: 15px;

		.doc-box {}

		a {
			background: var(--primarycolor);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px 0 15px;
			height: 36px;
			display: block;
			line-height: 35px;

			&:hover {
				background: var(--secondarycolor);
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.inhalt_mitte {
		max-width: 384px;
		background-color: #ffffff;
	}

	.quadrat_72_72_01 {
		width: 360px;
		height: 72px;
		margin-bottom: 12px;
		background-color: #ffffff
	}
}

.kasten_leer {
	display: none;
}