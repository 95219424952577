footer {
	.ausrichten_wetter {
		margin-left: -60px;
	}

	.footer-top {
		background-color: var(--light-grey);
		padding-top: 50px;
		padding-bottom: 20px;

		.widget {
			margin-bottom: 25px;
		}

		.widget-title {
			font-weight: bold;
			font-size: 20px;
			text-transform: uppercase;

			@media (max-width: 992px) {
				font-size: 18px;
			}
		}

		.address-li {
			padding: 0;
			margin: 0;

			li {
				list-style: none;
				position: relative;
				padding-left: 30px;

				a,
				span {
					padding: 5px 0;
					display: block;
					color: var(--defaultcolor);

					.fa {
						position: absolute;
						color: var(--secondarycolor);
						left: 0;
						top: 8px;
						font-size: 18px;
					}
				}
			}
		}
	}

	.footer-btm {
		background-color: var(--whitecolor);
		padding: 12px 0;

		.ft-btm {
			@media (min-width:993px) {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.ft-left-link {
				a {
					margin-right: 8px;
					padding: 4px 0;
					display: inline-block;
				}
			}
		}
	}

	.col5-five {
		width: 100%;
		float: left;
		padding: 0 15px;

		@media (min-width:993px) {
			width: 20%;

		}
	}
}