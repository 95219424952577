.indexpage {

	.dark-bg {
		background: var(--darkbluecolor);
		color: var(--whitecolor);

		p {
			margin-top: 40px;
			color: var(--whitecolor) !important;
		}
	}

	.blau_verlauf {
		background-image: linear-gradient(45deg, #a1d7fc 0%, #0370bb 50%);
		color: var(--whitecolor);

		p {
			margin-top: 40px;
			color: var(--whitecolor) !important;
		}
	}


	.testimonials {
		.item {
			padding: 15px 30px;
			margin-top: 50px;

			.it-cl-name {
				font-size: 18px;
				font-weight: bold;
			}

			.itm-img-bl {
				max-width: 180px;
				border-radius: 50%;
				overflow: hidden;
				margin-bottom: 20px;

			}

			.it-cl-name {
				color: var(--infocolor);
			}

			.it-date {
				font-weight: bold;
			}

			.it-content {
				h3 {
					color: var(--primarycolor);
					position: relative;
					display: inline-block;
					margin-top: 15px;
					padding-right: 95px;

					.stars {
						font-size: 14px;
						color: var(--secondarycolor);
						position: absolute;
						right: 0;
						top: 4px;
					}
				}
			}
		}
	}

	.brief {
		padding-top: 54px;
		padding-bottom: 54px;
		line-height: 28px;
		font-size: 16px;

		@media (max-width:992px) {
			padding-top: 40px;
			padding-bottom: 40px;
		}


		.home-serv-bl {
			margin: 20px 0;
		}

		.content-section {
			margin-top: 15px;
		}

		h2 {
			margin-bottom: 15px;
			margin-top: 10px;
		}

		p {

			color: #555;
		}

		ul {
			color: #555;

			.fa {
				padding-right: 10px;
			}

			a {
				color: inherit;
			}
		}

		.brief-image-right {
			margin-top: 20px;
		}

		.brief-image-right img {
			float: right;
			width: 92%;
		}

		.brief-image-left {
			overflow: hidden;
		}

		.brief-image-left img {
			float: left;
		}


		.feat-icn-bl {
			text-align: center;
			background-color: var(--light-grey);
			padding: 20px;
			margin-bottom: 15px;

			.fa {
				font-family: 'Font Awesome 5 Free';
				font-size: 32px;
				width: 80px;
				height: 80px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				margin-bottom: 15px;

				&.fa-anchor {
					border: 2px solid var(--darkbluecolor);
					color: var(--darkbluecolor);
				}

				&.fa-fort-awesome {
					border: 2px solid var(--primarycolor);
					color: var(--primarycolor);
				}

				&.fa-map-marker {
					border: 2px solid var(--secondarycolor);
					color: var(--secondarycolor);
				}

				&.fa-tree {
					border: 2px solid var(--infocolor);
					color: var(--infocolor);
				}
			}

			.feat-title {
				font-weight: 700;
				font-size: 20px;
				text-transform: uppercase;
			}
		}

	}

	.home-serv-bl {
		.serv-content {
			h3 {
				color: var(--defaultcolor);
			}
		}
	}

	.region {
		.r5208 {
			background-image: url(RESOURCE/img/home-region-angeln.jpg);
			height: 360px;
			background-position: center;
			background-size: cover;
		}

		.r5209 {
			background-image: url(RESOURCE/img/home-region-flenburg.jpg);
			height: 360px;
			background-position: center;
			background-size: cover;
		}

		.r5210 {
			background-image: url(RESOURCE/img/home-region-gluecksburg.jpg);
			height: 360px;
			background-position: center;
			background-size: cover;
		}

		.r5211 {
			background-image: url(RESOURCE/img/home-region-harrislee.jpg);
			height: 360px;
			background-position: center;
			background-size: cover;
		}
	}

	.grey-bg {
		background: #F7F8FA;
	}


	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
	}

	.uppercase {
		text-transform: uppercase;
	}

	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}


	}

}