@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.map-pane {
	/* 		
     background-color: #f5f5f5;
   */
	min-height: calc(100vh - 202px) !important;
	top: 180px;
	position: sticky;
	transition: all 0.25s ease 0s;
	/* height: auto !important; */

	.leaflet-popup-content {
		width: 240px !important;
	}

	#sidemap {
		width: 100%;
		height: 100vh;
		top: 0;
		display: block;

		.header {
			height: auto;
		}

		>div {
			height: 100%;
		}




		&.affix {
			@media (min-width:768px) {
				height: 100vh;
				top: 0;
				position: sticky !important;
			}

			@media (min-width:993px) {

				height: calc(100vh - 265px);
				top: 85px;
			}


		}

		.flex {
			width: 100%;
			height: 100%;

			>div {
				height: 100%;
				margin-top: 55px;
				height: calc(100% - 55px) !important;
			}
		}
	}

	.no-map {
		height: calc(100vh - 180px) !important;


		@media (min-width:768px) {
			height: calc(100vh - 184px) !important;
		}

		@media (max-width: 767px) {
			height: calc(100vh - 96px) !important;
		}

		&.affix {
			height: 100vh !important;
		}
	}

}

.flex-row {
	display: flex;
	flex-wrap: wrap;
}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.map-view {

		.mapnote {
			padding: 0 10px 20px 10px;

			@media all and (min-width: 768px) and (max-width: 991px) {
				min-height: unset;
				padding: 20px 10px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				padding: 20px 10px;
			}

			@media all and (min-width: 1200px) and (max-width: 1697px) {
				padding: 18px 10px;
			}
		}

		#sidemap {
			@media all and (min-width: 768px) and (max-width: 991px) {
				height: calc(100vh - 177px);
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				height: calc(100vh - 177px);
			}

			@media all and (min-width: 1200px) and (max-width: 1697px) {
				height: calc(100vh - 300px);
			}
		}
	}

	.mapnote {
		font-size: 15px;
		padding: 0 10px 20px 10px;
		background: #fff;

		@media (max-width: 767px) {
			padding: 15px 15px;
			font-size: 12px;
			min-height: 85px;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			font-size: 14px;
			padding-right: 10px;
			min-height: 135px;
		}

		@media all and (min-width: 992px) and (max-width: 1199px) {
			font-size: 14px;
		}
	}

	.mapconsent {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: rgba(26, 121, 187, 0.15);
		flex-direction: column;
		padding: 0 15px;

		.doc-box {}

		.mapconsent-button {
			background: var(--primarycolor);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px 0 15px;
			height: 36px;
			display: block;
			line-height: 35px;

			&:hover {
				background: var(--secondarycolor);
			}
		}
	}

	.result-pane {
		width: 100%;
		padding: 0 15px;

		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 35px 0px 0;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 18px;


			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			@media (min-width:993px) {
				display: none;
			}
		}

		.list-content {
			@media (min-width:993px) {
				display: none;
			}
		}

		.map-col {
			width: 100%;
		}
	}

	.show-map-list {
		position: absolute;
		margin-top: -35px;
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */
		padding: 25px 0;


		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}



	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			position: relative;

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: var(--primarycolor);
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}


			}
		}

		.main-price {
			text-decoration: line-through;
			opacity: 0.7;
		}




	}


	.search-result-properties {
		display: flex;
		text-align: center;
		justify-content: center;

		.ft-icon {
			text-align: center;
			display: inline-flex;
			align-items: center;
			color: #58a0d1;
			border: 1px solid #58a0d1;
			padding: 5px 10px;
			margin: 10px 5px;

			.number {
				margin-left: 5px;
			}
		}

	}



	.search-bar {
		padding: 220px 0 30px 0;

		&.search-banner {
			background-image: url(RESOURCE/img/bg-search-banner.jpg);
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;

			.form-control {
				margin-bottom: 12px;
			}
		}

		.adv-filt-form {
			background: rgba(255, 255, 255, 0.9);
			padding: 15px;
			margin-top: 12px;
		}

		.btn {
			margin: 0px, 0px, 0px, 0px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:992px) {

		.list-view {
			.map-pane {
				display: none;
			}

			.result-pane {
				display: block;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}

			.result-pane {
				display: none;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}


	.results {
		.unit-box {
			border: 1px solid var(--primarycolor);
			position: relative;
			margin-bottom: 15px;
			border-radius: 4px;

			.rent-rate {
				position: absolute;
				top: 60px;
				z-index: 1;
				right: 0;
				background-color: var(--infocolor);
				padding: 5px 10px;
				color: var(--whitecolor);
				font-weight: bold;
			}

			.unit-title {
				color: var(--whitecolor);
				padding: 10px;
				text-align: center;
				background-color: rgba(88, 160, 209, .9);
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
			}

			.img-content {
				position: relative;

				.fav-icon {
					position: absolute;
					left: 18px;
					bottom: 9px;
					color: var(--primarycolor);
					font-size: 18px;
				}

				.map-marker {
					position: absolute;

					bottom: 0;
					top: inherit;
					right: 0;

					i {
						color: var(--whitecolor);
					}
				}

				img {
					width: 100%;
				}
			}

			.unit-cont-bl {
				background-color: var(--whitecolor);
				padding: 10px 15px;
				height: 67px;
				overflow: hidden;
				margin-bottom: 10px;
			}

			.unit-feat {
				background-color: #fff;
			}
		}
	}


	.map-pane {
		@mixin flex;
		height: calc(100vh - 155px);

		@media (max-width:992px) {
			z-index: 50;
			padding: 0;
			height: 100vh;
		}
	}

	@media (min-width:768px) {


		.result-pane {
			.more-btn-panel {
				display: none;
			}


		}






	}

}

.rabatt-code-search {
	margin-top: 25px;
	background-color: #eee;
	padding: 10px;
	border-radius: 10px;
}