@import "common.css";

.btn_blue_300 {
	margin-left: 0px;
	margin-top: 12px;
	margin-bottom: 6px;
	background-color: #0082c5;
	color: #FFF !important;
	width: 300px;
}

.btn_blue_300:hover,
.btn_blue_300:focus,
.btn_blue_300:active,
.btn_blue_300.active {
	color: #FFF;
	background-color: #00689e;
}

.btn_orange_300 {
	margin-left: 0px;
	margin-top: 12px;
	margin-bottom: 6px;
	background-color: #f6ac15;
	color: #FFF !important;
	width: 300px;
}

.btn_orange_300:hover,
.btn_orange_300:focus,
.btn_orange_300:active,
.btn_orange_300.active {
	color: #FFF;
	background-color: #c58a11;
}

.unit-view-section {
	padding-top: 165px;
	padding-bottom: 50px;
}

.unit-view {

	margin-top: 48px;

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.v-img-gallery {
		margin-top: 20px;
	}

	.search-section {
		.searchbox {
			margin: 0 0 15px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.sidebar-unit {

		.col-sm-3,
		.col-sm-9 {
			width: 100%;
		}

		.col-sm-offset-3 {
			margin-left: 0;
		}

		.form-group {
			margin-bottom: 5px;
		}
	}

	.title-img {

		position: relative;
		width: 100%;
		height: auto;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}


	.stars {
		color: var(--infocolor);
	}

	.legend-cal {
		text-align: center;
		padding: 0;
		margin: 15px 0;

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding: 0;
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			.day {
				width: 30px;
				height: 30px;
				background-color: #7e7975;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;

				@media (max-width:992px) {
					width: 15px;
					height: 15px;
				}

				&.day-free {
					background: var(--white);
				}

				&.day-full {
					background: var(--cal_not_available);
				}

				&.day-end {
					background: linear-gradient(90deg, var(--cal_not_available) 20%, #fff 30%) !important;
				}

				&.day-start {
					background: linear-gradient(90deg, #fff 70%, var(--cal_not_available) 80%) !important;
				}
			}
		}
	}


	.main-section {
		position: relative;

		max-width: 1200px;

		h2 {
			margin-top: 40px;
			font-size: 22px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		edge_01 {
			margin-top: 0px;
			font-size: 22px;

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 15px 15px 5px 0px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				@mixin columnlayout 2;

				.fa {
					margin-right: 10px;
					color: var(--secondarycolor);
				}

				.prop.bool {
					font-weight: bold;
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.info-col-header {
			background-color: var(--primarycolor);
		}


		.info-col {
			margin-bottom: 30px;

			@media (max-width:992px) {
				margin-top: 30px;
			}

			/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */

			.info-col-header {
				height: 40px;
				padding: 1px;
				background-color: var(--primarycolor);
				color: #fff;
				text-align: center;

				h2 {
					margin-top: 10px;
					font-size: 18px;
				}
			}

			.info-col-main {
				border: 1px solid #888;
				border-bottom: none;
				width: 100%;
			}

			.subprice {
				width: 100%;
				border: 1px solid #888;
				border-top: none;
			}

			.fav-section {
				margin-top: 20px;
				border-bottom: 1px solid #888 !important;
				padding: 15px;
			}

		}

		.info-col {

			max-width: 800px;

			.info-col-main {

				padding: 15px;
			}

			.subprice {

				padding: 1px 15px 15px 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
					}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 470px;
			width: 100%;

			.mapconsent {
				height: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				background: rgba(26, 121, 187, 0.15);
				flex-direction: column;
				padding: 15px;

				.doc-box {}

				.mapconsent-button {
					background: var(--primarycolor);
					color: #fff;
					border-radius: 5px;
					padding: 0 15px 0 15px;
					height: 36px;
					display: block;
					line-height: 35px;

					&:hover {
						background: var(--secondarycolor);
					}
				}
			}

			@media (max-width:420px) {
				height: 510px;
			}

			.mapnote {
				font-size: 14px;
				padding: 0 0 20px;
				background: #fff;

			}

			>div {
				height: 350px;

				@media (max-width:991px) {
					height: 400px;
				}
			}

			&.no-map {
				height: auto;

				@media (max-width:420px) {
					height: auto;
				}

				>div {
					height: auto;

					@media (max-width:991px) {
						height: auto;
					}
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}

	.page-header {
		margin-top: 0;

		h1 {
			margin-top: 0;
		}
	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header {

			h1 {
				font-size: 24px;
			}
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	h2 {
		margin-top: 40px;
		font-size: 22px;
	}

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}

.edge_01 {
	margin-top: 0px;
}