@import "font-awesome.css";

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Black.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Black.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Black.svg#SourceSansPro-Black') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Bold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot');
	src: url('RESOURCE/fonts/SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-ExtraLight.svg#SourceSansPro-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Light.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

.asd__wrapper--full-screen {
	z-index: 999 !important;
}

.owl-carousel {
	z-index: inherit !important;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot');
	src: url('RESOURCE/fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-Regular.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot');
	src: url('RESOURCE/fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Indie Flower';
	src: url('RESOURCE/fonts/IndieFlower.eot');
	src: url('RESOURCE/fonts/IndieFlower.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/IndieFlower.woff2') format('woff2'),
		url('RESOURCE/fonts/IndieFlower.woff') format('woff'),
		url('RESOURCE/fonts/IndieFlower.ttf') format('truetype'),
		url('RESOURCE/fonts/IndieFlower.svg#IndieFlower') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url("RESOURCE/fonts/fa-solid-900.eot");
	src: url("RESOURCE/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-solid-900.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-solid-900.woff") format("woff"),
		url("RESOURCE/fonts/fa-solid-900.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}

@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-brands-400.eot");
	src: url("RESOURCE/fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-brands-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-brands-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-brands-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}

@font-face {
	font-family: 'FontAwesome';
	src: url('RESOURCE/fonts/fontawesome-webfont.eot?v=4.7.0');
	src: url('RESOURCE/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
		url('RESOURCE/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
		url('RESOURCE/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
		url('RESOURCE/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
		url('RESOURCE/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

.margin_right_12 {
	margin-right: 12px;
}

.margin_top_12 {
	margin-top: 12px;
}

.img_01 {
	margin-right: 6px;
	margin-bottom: 6px;
}

.fa_heart_01 {
	color: #58a0d1;
	font-weight: 900;
}

.fa_language_01 {
	color: #58a0d1;
	font-weight: 900;
	font-size: 24px;
}

.button_detail {
	background-color: #d4d4d4;
}

.margin_top_15 {
	margin-top: 15px;
}

.margin_top_12 {
	margin-top: 12px;
}

.margin_top_24 {
	margin-top: 24px;
}

.margin_12 {
	width: 93%;
	margin: 12px auto;
}

.doc-box {
	text-align: left;

	.container {
		width: auto;
	}
}

.margin_12:hover {
	background-color: #e19e16;
	transition: 0.4s;
}

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.fa-heart-o {
	font-weight: 900;
	color: #fff;
	font-size: 18px;
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #026B8E;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: none;
	border-bottom: none;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: rgb(102, 226, 218) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}

	.asd__day--selected {
		background: rgb(0, 166, 153) !important;
		color: white !important;
		opacity: 1 !important;
	}


}



/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.img-responsive {
	max-width: 100%;
}

body {
	font-family: var(--sourcepan);
	color: var(--defaultcolor);
	font-size: 16px;
}

.asd__mobile-close {
	width: 35px;
	font-size: 24px;
	height: 35px;
	border-radius: 3px;

	.asd__mobile-close-icon {
		position: relative;
		top: -5px;
	}
}

.btn-primary {
	background-color: var(--primarycolor);
	border-color: var(--primarycolor);
	color: var(--whitecolor);

	&:hover {
		background-color: var(--secondarycolor) !important;
		border-color: var(--secondarycolor) !important;
	}
}

.guestbox {
	td {
		.btn {
			font-family: arial, sans-serif;
		}
	}
}

.flex-row {
	@media (min-width:993px) {
		display: flex;

		.flex-col {
			display: flex;
		}
	}
}

.img-full {
	width: 100%;
}

.form-control {
	background: #fff !important;
	border-radius: 0;
}

select.form-control {
	height: 46px;
}

.min-row {
	margin-left: -5px;
	margin-right: -5px
}

.min-row .col-sm-6,
.min-row .col-sm-4 {
	padding-left: 5px;
	padding-right: 5px;
}

.btn {
	border-radius: 4px;
}

h1,
h2,
h3,
h4 {
	font-weight: 700;
}

.adv-filt-form .checkbox {
	margin-top: 0
}

.adv-filt-form .panel-title {
	margin-bottom: 12px;
}

.container-fluid {
	max-width: 1440px;
	width: 100%;
}

.btn-secondary {
	background-color: var(--secondarycolor);
	font-size: 16px;
	padding: 10px 14px;
	text-align: center;
	color: var(--whitecolor) !important;
	font-weight: bold;
}

.checkbox label:after,
.checkbox label:before {
	top: 3px !important;
}

a:focus,
a:hover {
	text-decoration: none;
}

.pad50 {
	padding: 50px 0;

	@media (max-width:992px) {
		padding: 25px 0;
	}
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
	margin-top: 0;
	line-height: 1.5;
}

a,
input,
button,
.btn,
*,
a:focus,
input:focus,
button:focus,
.btn:focus,
.checkbox input[type=checkbox]:focus+label:before {
	outline: none !important;
	outline-offset: 0 !important;
	text-decoration: none;
}

.bg-grey {
	background-color: var(--light-grey);
}

.next-prev-btns {
	position: relative;

	.owl-nav {
		position: absolute;
		top: 40%;
		left: 0;
		width: 100%;

		@media (max-width: 992px) {
			display: none;
		}

		button {
			width: 70px;
			height: 70px;
			border-radius: 50%;
			border-radius: 50%;
			box-shadow: 0 0 8px rgba(0, 0, 0, .21);
			background-color: var(--whitecolor) !important;
			position: absolute;
			left: 40px;
			font-size: 34px;

			@media (max-width:992px) {
				left: -6px;
			}

			span {
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: transparent;

				&:before {
					color: var(--blackcolor);
					font-family: 'FontAwesome';
					position: relative;
					font-size: 34px;
				}
			}

			&:hover {
				span {
					opacity: .3;
				}
			}

			&.owl-next {
				position: absolute;
				right: 40px;
				left: initial;

				span {

					&:before {
						content: "\f054";
						right: -5px;
					}
				}
			}

			&.owl-prev {
				span {

					&:before {
						content: "\f053";

					}
				}
			}
		}
	}
}

.content-col3 {
	@media (min-width: 993px) {
		.col-md-4 {
			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}

	@media (max-width: 992px) {
		.col-sm-6 {
			&:nth-child(2n+1) {
				clear: both;
			}
		}
	}
}

.searchbar,
.search-bar,
.sidebar-unit {
	.datepicker-trigger .asd__wrapper {
		@media (max-width:767px) {
			padding-top: 75px;
		}
	}

	.datepicker-trigger .asd__wrapper,
	.box.full-xxs {
		@media (max-width:480px) {
			padding-top: 75px;
		}

		@media (min-width:481px) {
			min-width: 320px;
		}
	}
}

.search-bar,
.search-form-area {
	.datepicker-trigger .asd__wrapper {
		@media (max-width:767px) {
			padding-top: 75px;
		}
	}

	.datepicker-trigger .asd__wrapper,
	.box.full-xxs {
		@media (max-width:480px) {
			padding-top: 75px;
		}

		@media (min-width:481px) {
			min-width: 320px;
		}
	}
}

.home-serv-bl {
	max-width: 550px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;

	.img-bl {
		margin-bottom: 15px;

		img {
			width: 100%;
			max-width: inherit;
		}
	}
}

p:last-child {
	margin-bottom: 0;
}

p {
	line-height: 28px;
}

h1 {
	@media (max-width: 992px) {
		font-size: 26px;
	}

	&.page-heading {
		margin-bottom: 0;
	}
}

h2 {
	@media (max-width: 992px) {
		font-size: 24px;
	}
}

h3 {
	@media (max-width: 992px) {
		font-size: 20px;
	}
}

h4 {
	@media (max-width: 992px) {
		font-size: 18px;
	}
}

.bg-li-orange {
	background-color: #fff6e5;
}

.form-horizontal .control-label {
	text-align: left;
	font-weight: bold;
	padding-bottom: 5px;
}

.payment-view {
	padding-top: 230px;
	padding-bottom: 70px;

	@media (max-width: 767px) {
		padding-top: 200px;
	}

	.center-block {
		.btn-primary {
			background-color: var(--primarycolor);
			border-color: var(--primarycolor);
			box-shadow: none;
		}
	}
}

.fa.fa-heart-o {
	font-family: "Font Awesome 5 Free";
	font-weight: 400;
}

.checkbox input[type=checkbox]:checked+label:after {
	font-family: "Font Awesome 5 Free" !important;
	font-weight: 600 !important;
}

.fa-facebook-f,
.fa-fort-awesome {
	font-family: 'Font Awesome 5 Brands' !important;
	font-weight: 400 !important;
}

.fa.fa-envelope {
	font-weight: 400 !important;
}