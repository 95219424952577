.booking-view {

	.form-control {
		height: 40px;
		box-shadow: none;
	}

	textarea.form-control {
		height: auto;
	}

	.coupon-v1 {
		padding: 20px;
		border: 1px solid #ccc;
		margin-top: 25px !important;
		margin-bottom: 20px;

		.btn.btn-success {
			height: 40px;
			border-radius: 0;
		}
	}

	.cart-details {

		margin: 30px 0 0 0;
		display: block;

		.unit-details {
			margin-top: 10px;
			padding-left: 20px;

			li {
				margin-left: 0px;
			}
		}
	}

	.pd-form {
		label {
			font-weight: normal;
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.price-table {

		td {
			.btn {
				display: none !important;
			}
		}
	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 700;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


	.pay-online-box {
		min-height: 240px;

		.amount-options-list {
			padding-left: 10px;
		}

		.amount-option-btn {
			list-style-type: none;
		}

		.amount-option {
			border: none;
			font-weight: bold;
			padding: 0;
			margin-bottom: 4px;
			margin-left: 10px;
		}

		.amount-or {
			margin-top: 2px;
			font-weight: normal;
			font-style: italic;
		}

		.bank-detail-box {
			padding-left: 10px;
		}
	}

	.payment-section {
		margin-top: 30px;
	}

}