:root {
	--introImg: url(RESOURCE/img/bg-banner-new.jpg);
	--introMobileImg: url(RESOURCE/img/banner-banner.Xc_770_770.jpg);
	--navbarbreak: 992px;
	--whitecolor: #fff;
	--darkbluecolor: #0273b9;
	--primarycolor: #58A0D1;
	--sourcepan: Source Sans Pro, sans-serif;
	--greycolor: #a0927e;
	--secondarycolor: #F6AC15;
	--infocolor: #BACE20;
	--light-grey: #f9f9f9;
	--defaultcolor: #666666;
	--blackcolor: #333;
	--cal_not_available: #cd7898;
}