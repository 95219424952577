@import "setup.css";

.indexpage {

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.home-serv {
		display: block;

		&:nth-child(3n+1) {
			clear: both;
		}
	}

	.banner {

		.owl-dots {
			display: none;
		}

		.item {
			width: 100%;
			min-height: 620px;
			height: 100vh;
			background-size: cover;
			background-position: no-repeat;
			background-position: center;

			@media (max-width:767px) {
				min-height: 560px;
				height: calc(100vh - 80px);
			}

			&.banner1 {
				background-image: url(RESOURCE/img/bg-banner-new-01.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/bg-banner-new-01.Xc_700x650_1_1.jpg);
				}
			}

			&.banner2 {
				background-image: url(RESOURCE/img/bg-banner-new-02.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/bg-banner-new-02.Xc_700x650_1_1.jpg);
				}
			}

			&.banner3 {
				background-image: url(RESOURCE/img/bg-banner-new-23.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/bg-banner-new-23.Xc_700x650_1_1.jpg);
				}
			}

			&.banner4 {
				background-image: url(RESOURCE/img/bg-banner-new-04.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/bg-banner-new-04.Xc_700x650_1_1.jpg);
				}
			}

			&.banner5 {
				background-image: url(RESOURCE/img/bg-banner-new-20.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/bg-banner-new-20.Xc_700x650_1_1.jpg);
				}
			}

			&.banner6 {
				background-image: url(RESOURCE/img/bg-banner-new-21.jpg);

				@media (max-width:992px) {
					background-image: url(RESOURCE/img/bg-banner-new-21.Xc_700x650_1_1.jpg);
				}
			}


		}
	}

	.iheader {

		min-height: 620px;
		height: 100vh;

		@media (max-width:767px) {
			min-height: 560px;
			height: calc(100vh - 80px);
		}

		.jumbotron {
			padding-top: 138px;
			text-align: center;
			background: transparent;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, 0);
			z-index: 2;

			img {
				display: inline-block
			}

			h1 {
				color: var(--primarycolor);
				border-bottom: 1px solid #333;
				display: inline-block;

				span {
					color: var(--infocolor);
				}
			}

			h2 {
				color: var(--primarycolor);
				display: block;
			}
		}

		position: relative;


		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1 {
					font-weight: 700;
					margin: 60px 0 0;
					font-size: 110px;

					@media (max-width:992px) {
						font-size: 70px;
					}
				}

				h2 {
					font-size: 24px;
					font-weight: 400;
					margin: 20px 0 0;

				}

			}

		}


	}



	.searchbar {

		width: 100%;
		position: absolute;
		bottom: 50px;
		left: 0;
		max-width: inherit;
		background: rgba(255, 255, 255, 0.35);
		padding: 20px 0;


		.row {
			margin-left: -5px;
			margin-right: -5px;
		}

		.searchbox {
			padding: 5px;

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}



		@media (min-width:993px) {
			max-width: 100%;
		}

	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}

}