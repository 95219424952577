@import "setup.css";

.welle {
	position: relative;
	z-index: 6;
	width: 2400px;
	height: 50px;
	background: rgba(255, 255, 255, 1);
	margin-top: 0;
	background-repeat: repeat-x;
}

.header {
	position: fixed;
	z-index: 5;
	width: 100%;
	background: rgba(255, 255, 255, 1);
	padding: 0px 0px;
	margin-top: 0px;

	@media (max-width:767px) {
		padding-bottom: 0;
	}

	.logo {
		max-width: 240px;
		display: block;
		margin-bottom: 12px;
		margin-top: 12px;
	}



	nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		.navbar-area {
			@media (min-width:993px) {
				display: flex;
				align-items: center;
			}

			@media (max-width:992px) {
				position: absolute;
				display: none;
				width: 100%;
				background-color: #fff;
				left: 0;
			}

			padding: 0;
			margin: 0;

			li {
				list-style: none;

				a {
					color: var(--primarycolor);

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}


	.nav-contact {
		a {

			@media (max-width:992px) {
				margin-left: 5px;
			}
		}

		.fa {
			padding-top: 6px;
			padding-left: 7px;
			font-size: 14px;
			width: 27px;
			height: 27px;
			background: var(--secondarycolor);
			color: #fff;
			border-radius: 100%;
		}


		span {
			padding: 17px 2px;
			color: var(--greycolor);
			font-size: 15px;

			@media (max-width:1170px) {
				display: none;
			}
		}


		@media (max-width:992px) {
			position: absolute;
			top: 20px;
			right: 24px;
		}
	}
}


.vo-nav-bar {

	margin-top: 20px;

	a.nav-item {
		font-size: 16px;
		font-weight: 500;
		letter-spacing: .05em;
		text-transform: uppercase;
		padding: 20px 10px;

		@media (max-width:992px) {
			padding: 10px;
		}

		&:hover {
			color: var(--greycolor);
			text-decoration: none;
		}
	}


	.icon {
		display: none;
	}

	.dropdown {
		float: left;
	}

	.input-group {

		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.dropdown-backdrop {
		display: none;
	}


	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
		border-radius: 0;
		margin-top: 12px;
	}

	.dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	}



	.dropdown.open .dropdown-content,
	.dropdown:hover .dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
			font-size: 24px !important;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}



}


@media screen and (max-width: var(--navbarbreak)) {


	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .dropdown {
		float: none;
	}

	.vo-nav-bar.opened .navbar-area {
		@media (max-width:992px) {
			display: block;
			box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
			top: 100%;
		}
	}

	.vo-nav-bar.opened .dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}
}